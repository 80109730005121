<template>
  <section class="city">
    <!-- HEADER -->
    <header class="hero-small">
      <div>
        <h1>
          {{ city ? city.title : 'Maisons Alysia, constructeur de maison' }}
        </h1>
      </div>
    </header>
    <app-spinner v-if="isLoading"></app-spinner>
    <app-error v-else-if="errors.length" title="Erreur" :messages="errors"></app-error>
    <main v-else-if="city">
      <section class="grid-container become-owner">
        <div class="grid-x grid-margin-x">
          <div class="cell auto"></div>
          <div class="cell large-8 small-10">
            <p v-html="transformToRichText(this.city.becomeOwnerText)" />
          </div>
          <div class="cell auto"></div>
        </div>
      </section>
      <section class="configurator">
        <div>
          <div class="configurator-title">
            <h2>Le chiffrage complet de votre projet en moins de 2 mn grâce à notre incroyable configurateur</h2>
          </div>
          <div class="configurator-subtitle">
            <p>Configurez gratuitement et sans engagement votre projet sur {{ city.name }}.</p>
            <p>Choisissez une des options suivantes pour commencer.</p>
          </div>
          <p class="configurator-more-about">
            <router-link :to="{ name: 'configurator' }">En savoir plus</router-link>
          </p>
          <configurator-search :inputPostalCode="`${city.postalCode}-${city.inseeNumber}`" />
        </div>
      </section>
      <section class="grid-container" v-if="agency">
        <card-type-img class="agency-card" :title="`Votre constructeur pour votre projet à ${city.name}`" v-if="members.length">
          <template v-slot:content>
            <div class="grid-x grid-margin-x align-center-middle">
              <template v-for="(member, i) in members">
                <img v-if="member.photo" class="cell member-media small-order-2 medium-order-1" :src="member.photo" :key="member.memberId" />
                <avatar v-else class="cell member-media small-order-2 medium-order-1" :key="i" />
              </template>
              <img v-if="mapMedia" :src="mapMedia.url" class="cell map-media small-order-1 medium-order-2"/>
            </div>
            <p>{{ agency.description }}</p>
          </template>
          <template v-slot:img>
            <img :src="outsideMedias[1] ? outsideMedias[1].url : require('@/assets/img/pages/alysia-exterieur.png')" alt="Maisons alysia" />
          </template>
        </card-type-img>
      </section>
      <section class="grid-container" v-else>
        <card-type isColumn class="no-agency">
          <template v-slot:content>
            <h3>Aucune agence Maisons Alysia ne prend en charge la commune de {{ city.name }}</h3>
            <p>Nous sommes en plein développement dans ce département, consultez les autres communes où Maisons Alysia est implanté !</p>
          </template>
        </card-type>
      </section>
      <section class="your-builder">
        <div class="grid-container">
          <div class="grid-x grid-margin-x">
            <div class="cell auto">
              <card-type isColumn>
                <template v-slot:content>
                  <h3>{{ city.firstCardTitle }}</h3>
                  <p>{{ city.firstCardText }}</p>
                </template>
                <template v-slot:img>
                  <img src="@/assets/img/pages/maison-campagne.svg" alt="" />
                </template>
              </card-type>
            </div>
          </div>
        </div>
      </section>
      <section class="related-cities">
        <div class="grid-container">
          <div class="grid-x grid-margin-x">
            <div class="cell auto">
            <h3>Ces communes pourraient également vous intéresser :</h3>
            <ul>
              <li v-for="relatedCity in city.relatedCities" :key="relatedCity.city">
                <router-link :to="{ path: relatedCity.url }">{{ relatedCity.name }}</router-link>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="second-card">
        <div class="grid-container">
          <div class="grid-x grid-margin-x">
            <div class="cell auto">
              <card-type :title="city.secondCardTitle" hasBigText class="card-big-text">
                <template v-slot:img>
                  <div class="card-big-text-illu">
                    <img class="character-image" src="@/assets/img/pages/character.svg" alt="" />
                  </div>
                </template>
                <template v-slot:bigText>
                  <p>{{ city.secondCardText }}</p>
                </template>
              </card-type>
            </div>
          </div>
        </div>
      </section>
      <section class="grid-container frame-cards-container">
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <frame-cards />
          </div>
        </div>
      </section>
    </main>
  </section>
</template>

<script>
import agencyApi from '@/services/api/agency';
import FrameCards from '@/components/general/FrameCards.vue';
import CardType from '@/components/general/CardType.vue';
import CardTypeImg from '@/components/general/CardTypeImg.vue';
import ConfiguratorSearch from '@/components/general/ConfiguratorSearch.vue';
import Storyblok from '@/services/storyblok';

export default {
  name: 'city',
  components: {
    ConfiguratorSearch,
    CardTypeImg,
    FrameCards,
    CardType,
  },
  data() {
    return {
      isLoading: true,
      errors: [],
      medias: [],
      members: [],
      agency: null,
      city: {
        tabTitle: 'Constructeur maison individuelle - Conseil & Expertise | Alysia',
        metaDescription: 'Maisons Alysia : la référence de la construction de maisons individuelles de qualité à des prix compétitifs.',
      },
    };
  },
  metaInfo() {
    return {
      title: this.city.tabTitle,
      description: this.city.metaDescription,
    };
  },
  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getData();
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  computed: {
    outsideMedias() {
      const outsides = this.medias.filter((media) => media.type === 'OUTSIDE');
      return outsides;
    },
    mapMedia() {
      const maps = this.medias.filter((media) => media.type === 'MAP');
      return maps[0] || null;
    },
  },
  methods: {
    transformToRichText(richText) {
      const result = Storyblok.richTextResolver.render(richText);
      return result.replace(/<p><\/p>/g, '<br/>');
    },
    async getData() {
      try {
        this.errors = [];
        this.medias = [];
        this.members = [];
        this.agency = null;

        try {
          await this.getCity();
        } catch (error) {
          throw new Error();
        }

        this.city.relatedCities = JSON.parse(this.city.relatedCities);
        this.city.relatedCities = this.city.relatedCities.map((city) => ({ name: city.name, url: city.url }));

        if (this.$route.params.dpt !== this.city.postalCode.slice(0, 2)) {
          throw new Error();
        }

        try {
          await this.getAgency();
          await Promise.all([this.getMembers(), this.getMedias()]);
        } catch (error) {
          this.agency = null;
        }
      } catch (error) {
        this.errors.push('La ville n\'est pas reconnue par nos services');
      }
    },
    async getCity() {
      try {
        const { cityName } = this.$route.params;
        const response = await Storyblok.get(`cdn/stories/cities/${cityName}`, {
          version: process.env.VUE_APP_STORYBLOK_ENV === 'development' ? 'draft' : 'published',
        });
        this.city = response.data.story.content;
      } catch (err) {
        throw err;
      }
    },
    async getAgency() {
      try {
        const postalCodeInseeNumber = `${this.city.postalCode}-${this.city.inseeNumber}`;
        if (postalCodeInseeNumber) {
          const response = await agencyApi.getByPostalCode(postalCodeInseeNumber);
          this.agency = response;
        } else {
          throw new Error('No postal code');
        }
      } catch (error) {
        throw error;
      }
    },
    async getMembers() {
      try {
        const response = await agencyApi.getAgencyMembers(this.agency.agencyId);
        this.members = response;
      } catch (error) {
        throw error;
      }
    },
    async getMedias() {
      try {
        const response = await agencyApi.getAgencyMedias(this.agency.agencyId);
        this.medias = response;
        this.mediasLoaded = true;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style lang="sass">
.city
  background: $medium-bg
  overflow-x: hidden

  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/cities/hero-city.jpg)

  .card-type-img
    margin: 3rem auto
    .content
      svg
        background: $line
        width: 116px
        height: 106px
        padding: 10px 5px
        fill: $off-white
        border-radius: $global-border-radius

      img
        object-fit: cover
        border-radius: $global-border-radius
        &.member-media
          height: 126px
          width: 116px
        &.map-media
          width: 207px
          height: 207px
      @include breakpoint(small down)
        text-align: center
        .grid-x
          img, svg
            margin-bottom: 1rem

  .become-owner
    background-color: $medium-bg
    padding: 8rem 0 5rem 0

    h2
      @include subtitle
      margin-bottom: 1.5rem
      text-align: center
      line-height: inherit
      font-weight: bold
      color: $black

    p:not(:last-child)
      margin-bottom: 1rem

    summary
      color: $primary
      cursor: pointer
      user-select: none

    details[open] summary
      margin-bottom: 0.5rem

    a
      font-size: inherit
      font-weight: inherit
      text-decoration: none
      color: $primary

  .agency-card
    margin: 6rem 0 3rem 0

  .no-agency
    margin: 6rem

    > div
      padding-top: 20px

  .configurator
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.8) 50%, rgba(32, 32, 32, 0.4) 100%), url(~@/assets/img/pages/home-hero.jpg)
    min-height: 650px

    &-title
      display: flex
      justify-content: center
      margin-bottom: 1rem

      h2
        @include heading-lg
        margin: 0

    &-subtitle
      margin-bottom: 1rem

      p
        line-height: 32px
        font-size: $font-lg
        font-weight: 400
        color: $white

    &-more-about
      margin-bottom: 2rem

      a
        font-weight: 700
        color: $primary
        text-decoration: none

  .your-builder
    padding: 6rem 0
  .second-card
    padding-top: 4rem

  .card-big-text
    margin-top: 1.5rem
    .card-big-text-illu
      background: url(~@/assets/img/pages/card-big-text-background-blue.png) no-repeat right bottom
      @media (max-width: 768px)
        width: 200px
        height: 200px
        border-radius: 1000px
        background: $primary
        overflow: hidden
        margin: auto
    .character-image
      width: 130px
      margin-left: auto
      margin-right: 30px

  .related-cities
    background-color: $primary
    padding: 4rem 0

    > div
      max-width: 1200px
      width: 90%

      h3
        text-align: center
        font-size: $font-xxl
        color: $white
        margin-bottom: 2rem

      ul
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(5, 1fr)
        column-gap: 1rem
        row-gap: 0.5rem
        padding: 0
        margin: 0 auto
        max-width: fit-content
        width: 100%

        a
          color: $white

  .frame-cards-container
    padding: 6rem 0

    .frame-cards
      margin: 0

  @media (max-width: 768px)
    .configurator
      &-title h2
        @include heading-md

      &-subtitle p
        @include infos
</style>
